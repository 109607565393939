<template>
  <div class="stub-redirect">redirecting</div>
</template>

<script>
  export default {
    name: 'StubRedirect',

    created() {
      // Check we not in PuppeteerRenderer
      if (!window.__PRERENDER_INJECTED && this.$route.meta.redirect) {
        window.location.href = this.$route.meta.redirect;
        return;
      }

      throw new Error('Redirect path is not provided');
    },
  };
</script>

<style scoped></style>
